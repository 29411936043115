<template lang="pug">
#pageTable
  v-container(grid-list-xl, fluid, style="min-height: calc(100vh - 64px - 50px)")
    v-layout(row, wrap)
      v-flex(md12, xs12).pa-0.mt-0
        v-card.elevation-0
          v-card-text
            v-layout(row, wrap).pb-4
                v-flex.md2.mt-3.text-xs-center.pa-0.my-0.mx-2.mt-0.mb-0.pa-1
                    v-btn(small, color="success", @click="setData(0, 0)", block).pa-0.my-0.mx-0.mt-0.mb-0 Mês Atual
                v-flex.md2.mt-3.text-xs-center.pa-0.my-0.mx-2.mt-0.mb-0.pa-1
                    v-btn(small, color="success", @click="setData(1, 0)", block).pa-0.my-0.mx-0.mt-0.mb-0 Mês Anterior
                v-flex.md2.mt-3.text-xs-center.pa-0.my-0.mx-2.mt-0.mb-0.pa-1
                    v-btn(small, color="success", @click="setData(2, 0)", block).pa-0.my-0.mx-0.mt-0.mb-0 Últimos 3 meses
                v-flex.md2.mt-3.text-xs-center.pa-0.my-0.mx-2.mt-0.mb-0.pa-1
                    v-btn(small, color="success", @click="setData(5, 0)", block).pa-0.my-0.mx-0.mt-0.mb-0 Últimos 6 meses

            v-layout(row, wrap)
              v-flex.md3.text-xs-center
                v-menu(v-model="menuDataInicial", :close-on-content-click="false", :nudge-right="40", lazy, transition="scale-transition", offset-y, full-width, min-width="290px")
                    template(v-slot:activator="{ on }")
                      v-text-field(:value="dataInicialFormatada", label="Data de Início", v-on="on", readonly, @click:clear="filtro.data_inicial = null;" )
                    v-date-picker(v-model="filtro.data_inicial", @input="menuDataInicial = false")
              v-flex.md3.text-xs-center

                v-menu(v-model="menuDataFinal", :close-on-content-click="false", :nudge-right="40", lazy, transition="scale-transition", offset-y, full-width, min-width="290px")
                    template(v-slot:activator="{ on }")
                      v-text-field(:value="dataFinalFormatada", label="Data de Início", v-on="on", readonly, @click:clear="filtro.data_final = null;" )
                    v-date-picker(v-model="filtro.data_final", @input="menuDataFinal = false")

              v-flex.md3.text-xs-center
                v-autocomplete(label="Questionário", :items="listQuestionarios", :disabled="!listQuestionarios.length", item-text="nome", item-value="id", color="primary", v-model="filtro.questionario_id", :clearable="true", :autocomplete="false", :filter="customFilter", required, v-validate="'required'", placeholder="Selecione o questionário")

              v-flex.md1.mt-3.text-xs-center.pa-1
                v-btn(color="primary", :loading='loading', :disabled="!filtro.questionario_id", @click="getData()").white--text Aplicar

      v-flex(md12, xs12).pa-0.mt-3
        v-card(v-if="loadingRelatorio", color="transparent").elevation-0
          v-card-text.text-xs-center
            v-progress-circular(:width="3", :size="40", color="primary", indeterminate, v-if="loadingRelatorio").mt-3
            p.title.mt-4.gray--text Carregando informações...

        v-tabs(v-model="tab", slider-color="primary", v-if="dadosBar && !loadingRelatorio",).elevation-0
          v-tabs-slider(color='primary')
          //- v-tab(:key="0").black--text.pa-2
          //-   v-icon(color="black", size="26") analytics
          //-   span.ml-2 Gráfico
          v-tab(:key="0").black--text.pa-2
            v-icon(color="black", size="20") table_rows
            span.ml-2 Tabela

          //- v-tab-item(:key="1")

          //-   box-chart(title='Questões respondidas', :xAxis="false", :data='dadosBar', :chart-color='[$vuetify.theme.chart_color_1, $vuetify.theme.chart_color_2, $vuetify.theme.chart_color_3, $vuetify.theme.chart_color_4]', type='bar-y-category-stack', v-if="dadosBar && !loadingRelatorio", :yAxis="labels")

          v-tab-item(:key="0")

            v-card(v-if="dadosBar && !loadingRelatorio").elevation-0
              v-card-text.pa-3
                v-layout(row, wrap)
                  v-flex.md8
                  v-flex.md4
                    //- v-autocomplete(label="Filtrar por auditado", :items="auditados", :disabled="!auditados.length", item-text="nomeAuditado", item-value="idAuditado", color="primary", v-model="filtro.auditado_id", :clearable="true", :autocomplete="false", :filter="customFilter", required, v-validate="'required'", placeholder="Selecione o auditado")
                v-data-table(:headers='headersRespostas', :items='dadosPorAuditado', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", v-if="dadosBar && !loadingRelatorio").elevation-0
                  template(slot='items', slot-scope='props')
                    td(width="5%").text-xs-center {{ props.item.idQuestao }}
                    td(width="30%").text-xs-left {{ props.item.nomeQuestao }}
                    td(width="30%")
                      BoxChart(
                        :type="'horizontal-bar'"
                        :chartColor="['#F5C343', '#4caf50', '#f44336', '#FCFABC']"
                        :data="props.item.opcoes"
                        :legend="false"
                      )
                    td.text-xs-center
                      v-btn(v-if="props.item.countFeedbacks", small, color="black", flat, @click="openDialogFeedbacks(props.item.feedbacks)").primary--text.caption.pa-0.mx-0.my-0.ma-0 Detalhes ({{ props.item.countFeedbacks }})
                      span(v-else) Nenhum feedback encontrado

    v-dialog(v-model="dialogFeedbacks", width="auto", scrollable, max-width="700px", persistent)
      v-card
        v-card-title.grey.lighten-2
          v-layout(row, wrap)
            v-flex.md11
              span.headline Detalhes dos feedbacks
            v-flex.md1.text-xs-right
              v-tooltip(top)
                v-btn(icon, slot="activator", @click.native="dialogFeedbacks = false; questaoFeedbacks = []").pa-0.mx-0.my-0.ma-0
                  v-icon close
                span Fechar
        v-divider
        v-card-text
          v-layout(row, wrap)
            v-flex(v-for="(categoria, index) in questaoFeedbacks", :key="index").md12
              v-layout(row, wrap).grey.lighten-3.pa-2
                v-flex.md10
                  span.font-weight-medium.subheading {{ categoria.nomeCategoria }}
                v-flex.md2.text-xs-right
                  span.font-weight-bold {{ categoria.qtdCategoria }}
              v-layout(row, wrap)
                v-flex(v-for="(feedback, index) in categoria.feedbacks", :key="index").md12
                  v-divider
                  v-layout(row, wrap).pa-2
                    v-flex.md10
                      span {{ feedback.nomeFeedback }}
                    v-flex.md2.text-xs-right
                      span {{ feedback.qtdFeedback }}
        v-divider
        v-card-actions.grey.lighten-2
          v-layout(row, wrap)
            v-flex.xs12.text-xs-right
              v-btn(color="black", flat, @click.native="dialogFeedbacks = false; questaoFeedbacks = []") Fechar

</template>

<script>
import _ from "lodash";
import BoxChart from "@/components/BoxChart";

export default {
    components: {
        BoxChart,
    },
    data() {
        return {
            filtro: {
                data_inicial: this.$moment().format("YYYY-MM-DD"),
                data_final: this.$moment().format("YYYY-MM-DD"),
                auditado_id: null,
            },
            headersRespostas: [
                { text: "#", align: "center", value: "id" },
                { text: "Texto", align: "left", value: "texto" },
                { text: "Respostas", align: "left" },
                { text: "Feedbacks", align: "center", value: "countFeedbacks" },
            ],
            tab: 0,
            questoesAll: [],
            auditorias: [],
            auditados: [],
            listQuestionarios: [],
            listAuditorias: [],
            loading: false,
            messageEmpty: false,
            loadingRelatorio: false,
            loadingAuditados: false,
            loadingAuditorias: false,
            menuDataInicial: false,
            menuDataFinal: false,
            dadosBar: null,
            respostasPorAuditado: [],
            respostasPorAuditado2: [],
            respostasAll: [],
            questaoFeedbacks: [],
            dialogFeedbacks: false,
        };
    },
    computed: {
        dataInicialFormatada() {
            return this.filtro.data_inicial
                ? this.$moment(this.filtro.data_inicial).format("DD/MM/YYYY")
                : this.$moment().format("DD/MM/YYYY");
        },
        dataFinalFormatada() {
            return this.filtro.data_final
                ? this.$moment(this.filtro.data_final).format("DD/MM/YYYY")
                : this.$moment().format("DD/MM/YYYY");
        },
        dadosPorAuditado() {
            const vm = this;
            let respostasAll = _.cloneDeep(vm.respostasAll);
            let respostasPorAuditado2 = _.cloneDeep(vm.respostasPorAuditado2);
            if (!_.isNil(this.filtro.auditado_id)) {
                let aux = _.filter(respostasPorAuditado2, {
                    idAuditado: vm.filtro.auditado_id,
                });
                return aux;
            }
            return respostasAll;
        },
    },
    mounted() {
        this.getQuestionarios();
    },
    methods: {
        openDialogFeedbacks(feedbacks) {
            const vm = this;
            vm.dialogFeedbacks = true;
            vm.questaoFeedbacks = feedbacks;
        },
        setData(mesesInicio, mesesFinal) {
            const vm = this;
            vm.filtro.data_inicial = this.$moment()
                .subtract(mesesInicio, "month")
                .startOf("month")
                .format("YYYY-MM-DD");
            vm.filtro.data_final = this.$moment()
                .subtract(mesesFinal, "month")
                .endOf("month")
                .format("YYYY-MM-DD");
        },
        countRespostas(opcoes, questoes) {
            let total = questoes && questoes.length ? questoes.length : 0;
            let opcoesArray = [];
            let respostasArray = [];
            _.map(questoes, function (q) {
                _.map(q.opcoes_questao, function (o) {
                    opcoesArray.push(o);
                });
            });
            _.map(opcoes, function (o) {
                let quantidade = _.sumBy(opcoesArray, function (a) {
                    return a.idOpcao == o.idOpcao && a.quantidade == 1;
                });
                let porcentagem = parseFloat(
                    (quantidade / total) * 100,
                ).toFixed(1);
                let obj = {};
                // obj[`${o.nomeOpcao}`] = `${porcentagem}$`
                // obj['value'] =
                respostasArray.push({
                    // idOpcao: o.idOpcao,
                    category: o.nomeOpcao,
                    // quantidade: quantidade,
                    value: parseInt(quantidade),
                    color: o.cor,
                });
            });
            return respostasArray;
        },
        getOpcoesPergunta(q) {
            let opcoesPergunta = [];
            _.map(q.opcoes_questao, function (o) {
                opcoesPergunta.push({
                    idOpcao: o.id,
                    nomeOpcao: o.texto,
                    cor: o.cor ? o.cor : "#4caf50",
                    quantidade:
                        q.resultado && q.resultado.opcao_questao_id == o.id
                            ? 1
                            : 0,
                });
            });
            return opcoesPergunta;
        },
        getOpcoes(questoes) {
            const vm = this;
            let questoesAll = [];
            let respostasQuestoes = [];
            _.map(questoes, function (q) {
                questoesAll.push({
                    idQuestao: q.id,
                    nomeQuestao: q.texto,
                    opcoes_questao: vm.getOpcoesPergunta(q),
                });
            });
            let groupByQuestoes = _.groupBy(questoesAll, "idQuestao");
            for (var prop in groupByQuestoes) {
                respostasQuestoes.push({
                    idQuestao: groupByQuestoes[prop][0].idQuestao,
                    nomeQuestao: groupByQuestoes[prop][0].nomeQuestao,
                    opcoes: vm.countRespostas(
                        groupByQuestoes[prop][0].opcoes_questao,
                        groupByQuestoes[prop],
                    ),
                    total: groupByQuestoes[prop].length,
                });
            }

            console.log(respostasQuestoes);
            return respostasQuestoes;
        },
        async createData() {
            const vm = this;
            let respostasQuestoes = [];
            let arrayQuestoes = [];
            _.map(vm.auditorias, (auditoria) => {
                let filterResultado = _.filter(
                    auditoria.questoesAll,
                    "resultado",
                );
                vm.respostasPorAuditado.push({
                    idAuditoria: auditoria.idAuditoria,
                    idAuditado: auditoria.idAuditado,
                    nomeAuditado: auditoria.nomeAuditado,
                    questoesAll: filterResultado,
                    questoes: vm.getOpcoes(filterResultado),
                });
                vm.auditados.push({
                    idAuditado: auditoria.idAuditado,
                    nomeAuditado: auditoria.nomeAuditado,
                });
            });
            _.map(vm.respostasPorAuditado, (auditado) => {
                _.map(auditado.questoesAll, (questao) => {
                    arrayQuestoes.push({
                        idAuditado: auditado.idAuditado,
                        idQuestao: questao.id,
                        nomeQuestao: questao.texto,
                        opcoes_questao: vm.getOpcoesPergunta(questao),
                        questoesAll: auditado.questoesAll,
                        countFeedbacks: vm.countFeedbacks(questao),
                        feedbacks: vm.getFeedbacks(questao),
                    });
                });
            });
            vm.respostasPorAuditado2 = arrayQuestoes;
            let groupByQuestoes = _.groupBy(arrayQuestoes, "idQuestao");
            for (var prop in groupByQuestoes) {
                vm.respostasAll.push({
                    idAuditado: groupByQuestoes[prop][0].idAuditado,
                    idQuestao: groupByQuestoes[prop][0].idQuestao,
                    nomeQuestao: groupByQuestoes[prop][0].nomeQuestao,
                    opcoes: vm.countRespostas(
                        groupByQuestoes[prop][0].opcoes_questao,
                        groupByQuestoes[prop],
                    ),
                    total: groupByQuestoes[prop].length,
                    countFeedbacks: groupByQuestoes[prop][0].countFeedbacks,
                    feedbacks: groupByQuestoes[prop][0].feedbacks,
                });
            }
        },
        getFeedbacks(questao) {
            let arrayFeedbacks = [];
            let arrayAux = [];
            questao.resultados.map((resultado) => {
                resultado.anexos.map((anexo) => {
                    anexo.feedbacks_anexos.map((obj) => {
                        arrayFeedbacks.push(obj.feedback);
                    });
                });
            });
            let groupFeedbcksByCategoria = _.groupBy(
                arrayFeedbacks,
                "categoria_feedback_id",
            );
            for (let prop in groupFeedbcksByCategoria) {
                let groupFeedbcksById = _.groupBy(
                    groupFeedbcksByCategoria[prop],
                    "id",
                );
                let arrayFeedbacksById = [];
                for (let propFeedback in groupFeedbcksById) {
                    arrayFeedbacksById.push({
                        nomeFeedback: groupFeedbcksById[propFeedback][0].texto,
                        qtdFeedback: groupFeedbcksById[propFeedback].length,
                    });
                }
                arrayAux.push({
                    nomeCategoria:
                        groupFeedbcksByCategoria[prop][0].categoria_feedback
                            .texto,
                    qtdCategoria: groupFeedbcksByCategoria[prop].length,
                    feedbacks: arrayFeedbacksById,
                });
            }
            return arrayAux;
        },
        countFeedbacks(questao) {
            let countFeedbacks = 0;
            questao.resultados.map((resultado) => {
                resultado.anexos.map((anexo) => {
                    countFeedbacks += anexo.feedbacks_anexos.length;
                });
            });
            return countFeedbacks;
        },
        async manupulateData() {
            const vm = this;
            vm.dadosBar = null;
            vm.dadosBar = [];
            vm.labels = [];
            vm.dadosBar.push({
                name: "Aguardando Confirmação",
                type: "bar",
                stack: 10,
                label: {
                    show: true,
                    position: "insideRight",
                },
                data: [],
            });
            vm.dadosBar.push({
                name: "Válidas",
                type: "bar",
                stack: 10,
                label: {
                    show: true,
                    position: "insideRight",
                },
                data: [],
            });
            vm.dadosBar.push({
                name: "Inválidas",
                type: "bar",
                stack: 10,
                label: {
                    show: true,
                    position: "insideRight",
                },
                data: [],
            });

            _.map(vm.auditorias, (auditoria) => {
                vm.labels.push(auditoria.nomeAuditado);
                let filterResultado = _.filter(
                    auditoria.questoesAll,
                    "resultado",
                );
                let questoesAguardando = _.filter(
                    filterResultado,
                    function (questao) {
                        return questao.resultado.status == 1;
                    },
                ).length;
                let questoesValidas = _.filter(
                    filterResultado,
                    function (questao) {
                        return questao.resultado.status == 2;
                    },
                ).length;
                let questoesInvalidas = _.filter(
                    filterResultado,
                    function (questao) {
                        return questao.resultado.status == 3;
                    },
                ).length;
                vm.dadosBar[0].data.push(questoesAguardando);
                vm.dadosBar[1].data.push(questoesValidas);
                vm.dadosBar[2].data.push(questoesInvalidas);
            });
            setTimeout(() => {
                vm.loadingRelatorio = false;
            }, 2000);
        },
        async getQuestionarios() {
            const vm = this;
            try {
                vm.loading = true;
                const response = await vm.$axios.get("/questionario", {
                    params: { ativo: 1 },
                });
                // vm.listQuestionarios = _.reject(response.data.rows, { padrao : 0 });
                vm.listQuestionarios = response.data.rows || [];
                vm.loading = false;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async getData() {
            const vm = this;
            vm.dadosbar = [];
            try {
                vm.loadingRelatorio = true;
                vm.auditorias = [];
                const response = await vm.$axios.get(`/auditoria-relatorio`, {
                    params: {
                        questionario_id: vm.filtro.questionario_id,
                        fields: "auditado,usuario,questionario,opcoes_questao",
                        data_inicial: this.$moment(
                            vm.filtro.data_inicial,
                        ).format("YYYY-MM-DD"),
                        data_final: this.$moment(vm.filtro.data_final).format(
                            "YYYY-MM-DD",
                        ),
                    },
                });

                _.map(response.data.rows, (auditoria) => {
                    vm.auditorias.push({
                        idAuditoria: auditoria.id,
                        idAuditado: auditoria.auditado.id,
                        nomeAuditado: auditoria.auditado.nome,
                        questoes: auditoria.questoes,
                        questoesFechadas: auditoria.questoesFechadas,
                        questoesAll: _.concat(
                            auditoria.questoes,
                            auditoria.questoesFechadas,
                        ),
                        status: auditoria.status,
                        dataInicio: auditoria.data_inicio,
                    });
                });
                if (vm.auditorias.length) {
                    vm.respostasPorAuditado = [];
                    vm.respostasAll = [];
                    vm.manupulateData();
                    vm.createData();
                    vm.messageEmpty = false;
                } else {
                    vm.loadingRelatorio = false;
                    vm.auditorias = [];
                    vm.dadosBar = null;
                    vm.messageEmpty = true;
                }
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loadingRelatorio = false;
            }
        },
        customFilter(item, queryText, itemText) {
            const hasValue = (val) => (val != null ? val : "");
            const text = hasValue(item.nome || item.nomeAuditado);
            const query = hasValue(queryText);
            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            );
        },
    },
};
</script>

<style lang="scss" scoped>
#pageTable {
    background-color: #eeeeee;
}
.shadow-custom {
    background-color: #ffffff;
    /* border-radius: 15px; */
}
</style>
