<template lang="pug">
        e-chart(:path-option='computeChartOption', height='250px', width='100%')
</template>

<script>
import _ from "lodash";
import EChart from "@/components/EChart";

export default {
    components: {
        EChart,
    },
    props: {
        title: String,
        footer: false,
        gradient: {
            type: Boolean,
            default: false,
        },
        subTitle: String,
        icon: String,
        cardColor: {
            type: String,
            default: "white",
        },
        iconColor: {
            type: String,
            default: "success",
        },
        type: String,
        legend: Boolean,
        chartColor: Array,
        data: Array,
        yAxis: {
            type: Boolean,
            default: true,
        },
        yAxis: Array,
        btn: Object,
    },
    data() {
        return {
            defaultOption: [
                ["dataset.source", this.data],
                ["xAxis.show", this.xAxis],
                ["yAxis.show", this.yAxis],
                // ['grid.top', '250%'],
                // ['grid.left', '10'],
                // ['grid.bottom', '10'],
                // ['grid.right', '10'],
                ["color", this.chartColor],
                [],
            ],
        };
    },
    computed: {
        computeCardDark() {
            return this.cardColor !== "white";
        },
        computeChartOption() {
            let options = this.defaultOption.slice();
            switch (this.type) {
                case "bar-y-category":
                    // options.push(['series[0].type', 'bar'])
                    // options.push(['legend.data', ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']])
                    options.push([
                        "yAxis",
                        {
                            type: "category",
                            data: _.map(this.data, "category"),
                        },
                    ]);
                    options.push(["xAxis", { type: "value" }]);
                    options.push([
                        "series",
                        [{ type: "bar", data: _.map(this.data, "value") }],
                    ]);
                    options.push(["series", this.data]);
                    break;
                case "bar-y-category-stack":
                    options.push(["grid"], {
                        left: 0,
                        right: 0,
                        bottom: 0,
                        containLabel: true,
                    });
                    options.push(["legend.x", "center"]);
                    options.push(["legend.align", "left"]);
                    options.push(["legend.itemGap", 15]);
                    options.push(["legend.itemHeight", 18]);
                    options.push(["legend.padding", [0, 5]]);
                    options.push(["legend.textStyle.padding", 0]);
                    options.push(["legend.textStyle.verticalAlign", "left"]);
                    options.push(["legend.textStyle.fontWeight", "bold"]);
                    options.push(["legend.textStyle.fontSize", 14]);
                    options.push(["legend.orient", "horizontal"]);
                    options.push([
                        "yAxis",
                        { type: "category", data: this.yAxis },
                    ]);
                    options.push(["xAxis", { type: "value" }]);
                    options.push([
                        "series",
                        [{ type: "bar", data: _.map(this.data, "value") }],
                    ]);
                    options.push(["series", this.data]);

                    break;
                case "bar":
                    options.push(["series[0].type", "bar"]);
                    options.push(["series[0].barWidth", "50%"]);
                    options.push([
                        "xAxis",
                        {
                            type: "category",
                            data: _.map(this.data, "category"),
                        },
                    ]);
                    options.push(["yAxis", { type: "value" }]);
                    options.push([
                        "series",
                        [{ type: "bar", data: _.map(this.data, "value") }],
                    ]);
                    break;
                case "horizontal-bar":
                    options.push(["series[0].type", "bar"]);
                    options.push(["series[0].barWidth", "50%"]);
                    options.push(["xAxis", { type: "value", minInterval: 1 }]);
                    options.push([
                        "yAxis",
                        {
                            type: "category",
                            data: _.map(this.data, "category"),
                        },
                    ]);

                    options.push([
                        "series",
                        [
                            {
                                type: "bar",
                                data: this.data.map((item) => ({
                                    value: item.value,
                                    itemStyle: {
                                        color: item.color,
                                    },
                                })),
                            },
                        ],
                    ]);

                    break;
                case "pie":
                    if (this.legend !== false) {
                        options.push(["legend.x", "right"]);
                        options.push(["legend.align", "left"]);
                        options.push(["legend.itemGap", 15]);
                        options.push(["legend.itemHeight", 18]);
                        options.push(["legend.padding", [0, 5]]);
                        options.push(["legend.textStyle.padding", 0]);
                        options.push([
                            "legend.textStyle.verticalAlign",
                            "left",
                        ]);
                        options.push(["legend.textStyle.fontWeight", "bold"]);
                        options.push(["legend.textStyle.fontSize", 14]);
                        options.push(["legend.orient", "vertical"]);
                    }
                    //
                    // console.log(this.legend);
                    options.push(["series[0].radius", ["45%", "75%"]]);
                    options.push(["label.fontWeight", "bold"]);
                    options.push(["series[0].type", "pie"]);
                    options.push(["series[0].labelline.show", false]);
                    options.push(["series[0].label.show", false]);

                    options.push(["series[0].emphasis.label.show", false]);

                    options.push(["series[0].top", 50]);

                    // options.push([
                    //     "series[0].dimensions",
                    //     ["date", "open", "close", "highest", "lowest"],
                    // ]);

                    break;
                case "area":
                    options.push(["series[0].type", "line"]);
                    options.push(["series[0].smooth", true]);
                    options.push(["xAxis.boundaryGap", false]);
                    options.push(["series[0].areaStyle", {}]);
                    if (this.gradient) {
                        options.push([
                            "series[0].areaStyle",
                            {
                                normal: {
                                    color: new window.echarts.graphic.LinearGradient(
                                        0,
                                        0,
                                        0,
                                        1,
                                        [
                                            {
                                                offset: 0,
                                                color: this.chartColor[0],
                                            },
                                            {
                                                offset: 1,
                                                color: this.chartColor[1],
                                            },
                                        ],
                                    ),
                                },
                            },
                        ]);
                    }
                    break;
                default:
                    // line
                    options.push(["series[0].smooth", true]);
                    options.push(["xAxis.boundaryGap", false]);
                    break;
            }

            console.log(options);
            return options;
        },
    },
};
</script>

<style lang="scss" scoped>
.shadow-custom {
    /* box-shadow: 0px 2.29741px 13.7844px rgba(0, 0, 0, 0.15); */
    /* border-radius: 25.2715px; */
    /* background-color: #FFFFFF; */
}
</style>
